.movies__list {
    margin-bottom: 20px;
    margin-right: 20px;
}

    .movies__list-title {
        color: #8be7ae;
    }

    .movies__list-description {
        color: #ffffff;
        font-size: 18px;
        text-decoration: underline;
        text-decoration-color: #b9f7d1;
        text-decoration-thickness: .6px;
        text-underline-offset: 4px;
    }


.movies__movies {
    display: grid;
    min-height: 300px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* Responsive grid with minimum width of 200px */
    grid-gap: 20px; /* Spacing between grid items */
    margin-right: 20px;
}
    .movie-container {
        position: relative; /* Enable relative positioning for the image */
    }

    .movie-container img {
        width: 100%;
        height: auto;
        display: block;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform .4s ease-in, border-color .6s ease;
        border: 2px solid transparent; /* Initially transparent border */
    }

    .movie-container:hover img {
        border-color: #8be7ae; /* Change the border color on hover */
        transform: scale(1.06);
        transition: .6s;
        
    }

    .details {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        background-color: #8be7aef3;
        width: 50%;
        max-width: 450px; /* Adjust the width as needed */
        padding: 15px;
        max-height: auto;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    }
    
    .details-content {
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
    }
    
    .detail-container {
        display: flex;
        align-items: center; /* Align items vertically */
    }
    
    .details-image {
        width: 100px;
        height: 140px;
        margin-right: 10px; /* Add some space between the image and the text */
    }
    
    .details-info {
        flex-grow: 1; /* Allow this container to grow to fill the remaining space */
    }
    
    .details-title {
        color: #dc5318;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;

    }
    
    .details-rating {
        font-size: 14px;
    }

    .details-genres {
        font-size: 14px;
    }
    
    .description {
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;
    }


    .reviews-header {
        font-size: 15px;
        font-weight: 500;
        color: #d9571f;
        margin-bottom: 5px;
    }

    .review-container {
        width: 100%;
        border-bottom: .1px solid #d9571f;
        font-size: 13px;
    }

    .user-text {
        cursor: pointer;
    }

    
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: #000000;
        font-size: 20px;

    }
    
    

