.App {
  text-align: center;
}


body {
  background-color: #a74a22;
    color: white;
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
}

.container {
  margin-top: 80px;
  margin-left: 70px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}


.navbar {
  display: flex;
  justify-content: center;
  padding: 10px 0; /* Add some padding to the navbar */
}

  .navbar-nav.ml-auto {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .nav-item a{
    text-decoration: none;
    transition: 1.4s;
    transform: scale(.7);
    color: #ffffff; 
    font-size: 22px;
  }

  .nav-item a:hover::before {
    transform: scale(0);
    /*box-shadow: 0 0 0px #60f4a0; */
  }

  .nav-item a:hover {
    color: #bbf3d0;
    transition: .6s;
    text-shadow: 0 0 5px #35efe9;
  }

  .nav-link {
    transition: 1.4s;
    transform: scale(.7);
    text-decoration: none;
    color: #ffffff; 
    margin-right: 10px;

  }

  .nav-link:hover::before {
    transform: scale(0);
    /*box-shadow: 0 0 0px #60f4a0; */
  }

  .nav-link:hover {
    color: #bbf3d0;
    transition: .6s;
    text-shadow: 0 0 5px #35efe9;
  }

  @media (max-width: 600px) {
    .nav-link {
      font-size: 16px; /* Decrease font size on smaller screens */
    }
  }





.loading
{
    text-align: center;
    color: #8be7ae;
}
