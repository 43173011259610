.image-container {
  text-align: center;
}

.confidence {
  font-size: 13px;
  color: #53f9a6;
  text-align: center;
}


.image-wrapper {
  position: relative;
  width: 400px; /* Ensure the image fills the container */
  margin-bottom: 30px;
}

.image-wrapper img {
  width: 100%; /* Ensure the image fills the container */
  
}


.object-detection-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: -20px;
  margin-bottom: 20px;
  margin-top: -20px;
}

.highlighter {
  background: #f97f2d12;
  border: 1px dashed #f97f2d;
  z-index: 1;
  position: absolute;
}

.info {
  position: absolute;
  font-size: 7px;
  color: #53f9a6;
  top: -16px;
}

.input {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-wrap: wrap; /* Allow items to wrap if they exceed container width */
  margin-bottom: 30px;
}

.upload-button {
  font-weight: 500;
  --c:  #a2f0c0; /* the color*/
  
  box-shadow: 0 0 0 .1em inset var(--c); 
  --_g: linear-gradient(var(--c) 0 0) no-repeat;
  background: 
    var(--_g) calc(var(--_p,0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p,0%)) 0%,
    var(--_g) calc(var(--_p,0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p,0%)) 100%;
  background-size: 50.5% calc(var(--_p,0%)/2 + .5%);
  outline-offset: .1em;
  transition: background-size .4s, background-position 0s .4s;
  border-radius: 5px;
}

.upload-button:hover {
  --_p: 100%;
  transition: background-position .4s, background-size 0s;
}

.upload-button:active {
  box-shadow: 0 0 9e9q inset rgba(59, 240, 42, 0.6); 
  background-color: var(--c);
  color: #fff;
}

.upload-button {
  cursor: pointer;
  padding: .2em .6em;
  border: none;
  color: #fff;
  width: 150px;
}