.vinyl__header {
    font-size: 24px;
    color: #8be7ae;
}


.vinyl__vinyls {

    margin-right: 20px;
}
    .vinyl-container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        width: 100%; /* Set width to 100% to force tracks to display in rows of one */
        border-bottom: 1px solid #8be7ae;
        padding-bottom: 16px;
    }

    .vinyl-container img {
        width: 90px;
        height: 90px;
        margin-right: 20px;
    }

    .vinyl-container img:hover {
        transition: all 1s;
        transform:scale(1.2);
    }

    .vinyl-detail {
        display: flex;
        flex-direction: column; /* Arrange track details vertically */
    }

    .vinyl-detail p {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        text-align: left; /* Align text to the left */
    }