.featurescard {
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 30px;

}
  
  .featurescard__header {
    font-weight: bold;
    font-size: 24px;
    color: #8be7ae;
  }
  
  .featurescard__body {
    list-style-type: none;
    padding: 0;
  }
  
  .featurescard__item {
    padding-inline-start: 4px;
    padding-block-start: 16px;
    padding-bottom: 10px;
    border-image: linear-gradient(to right, #cb5928, #8fe1afc7) 1;

  /* Ensure the border image is repeated to fill the border */
  border-image-slice: 1;

  /* Set border width */
  border-width: 0 0 1px 0;

  /* Optionally, you can set other border properties */
  border-style: solid;
    width: 94%;
    color: #ffffff;
    
  }
  
