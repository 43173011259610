.spotify-recommendation-artists {
  margin-right: 20px;
}

.spotify-recommendation-artists__input-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-wrap: wrap; /* Allow items to wrap if they exceed container width */
  margin-bottom: 20px;

  
}
  .textbox{
    margin-right: 20px;
    width: 200px;
    border-width: 0;
    text-align: center;
  }

  .get-button {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-wrap: wrap; /* Allow items to wrap if they exceed container width */
  }

  .get-button {
    width: 60px;
  }

  .get-button {
    font-weight: 500;
    --c:  #a2f0c0; /* the color*/
    
    box-shadow: 0 0 0 .1em inset var(--c); 
    --_g: linear-gradient(var(--c) 0 0) no-repeat;
    background: 
      var(--_g) calc(var(--_p,0%) - 100%) 0%,
      var(--_g) calc(200% - var(--_p,0%)) 0%,
      var(--_g) calc(var(--_p,0%) - 100%) 100%,
      var(--_g) calc(200% - var(--_p,0%)) 100%;
    background-size: 50.5% calc(var(--_p,0%)/2 + .5%);
    outline-offset: .1em;
    transition: background-size .7s, background-position 0s .7s;
    border-radius: 5px;
  }

  .get-button:hover {
    --_p: 100%;
    transition: background-position .7s, background-size 0s;
  }

  .get-button:active {
    box-shadow: 0 0 9e9q inset rgba(59, 240, 42, 0.6); 
    background-color: var(--c);
    color: #fff;
  }

  .get-button {
    cursor: pointer;
    padding: .1em .6em;
    border: none;
    color: #fff;
  }


.spotify-recommendation-artists__artists {
  display: flex;
  flex-direction: column; /* Ensure tracks are displayed in a column */
}
  .artist-container {
    

    padding-bottom: 16px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    
    margin-bottom: 40px;
    width: 100%; /* Set width to 100% to force tracks to display in rows of one */
  }

  .artist-container img {
    width: 110px;
    height: 110px;
    transition: all 1s;
  }

  .artist-container img:hover {
    transition: all 1s;
    transform:scale(1.15);
  }

    .artist-detail p{
      display: flex;
      flex-direction: column; /* Arrange track details vertically */
      margin-left: 10px;
      font-size: 18px;
    }



  .artists-container {
    border-bottom: 1px solid #8be7ae;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%; /* Set width to 100% to force tracks to display in rows of one */
  }

  .artists-container img {
    width: 100px;
    height: 100px;
    transition: all 1s;
  }

  .artists-container img:hover {
    transition: all 1s;
    transform:scale(1.2);
  }


    .artists-detail {
      display: flex;
      flex-direction: column; /* Arrange track details vertically */
      margin-left: 20px;
    }

      .name{
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 400;
        text-align: left; /* Align text to the left */
      }

      .genres {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        text-align: left; /* Align text to the left */

        text-decoration-line: underline;
        text-decoration-style: dotted;
        text-decoration-color: #d6550e;
      }