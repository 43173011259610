.description {
    margin-left: 10px;

  }
    
    .description__header {
      padding-top: 2px;
      font-size: 20px;
      font-weight: bold;
      color: #b9e5ca;
    }
    
    .description__body {
        list-style-type: none;
        padding: 0;
    }
    
    .description__item {
      padding-inline-start: 4px;
      padding-block-start: 14px;
      padding-bottom: 10px;
      border-image: linear-gradient(to right, #cb5928, #8fe1afc7) 1;
  
    /* Ensure the border image is repeated to fill the border */
    border-image-slice: 1;
  
    /* Set border width */
    border-width: 0 0 1px 0;
  
    /* Optionally, you can set other border properties */
    border-style: solid;
      width: 94%;
      color: #ffffff;
      
    }
    
  