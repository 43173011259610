.dashboard {
    margin-right: 30px;
    margin-top: 20px;
}
  
.dashboard__header {
  font-size: 24px;
  color: #8be7ae;
  margin-left: 10px;
}

