.mangaplan {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* Responsive grid with minimum width of 200px */
    grid-gap: 20px;
    grid-row-gap: 0px;
    grid-auto-rows: minmax(auto, 1fr);
    
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
}

    .manga-container {
        position: relative; /* Enable relative positioning for the image */
        
    }

    .manga-container p {
        text-align: center;
        
    }

    .plan-img {
        width: 100%; /* Ensure the image fills the container */
        height: 230px; /* Maintain aspect ratio */
        display: block;
        border-radius: 5px; /* Optional: Add border-radius for rounded corners */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow effect */
        transition: transform .4s ease-in, border-color .6s ease;
        border: 2px solid transparent; /* Initially transparent border */
        
    }

    .plan-img:hover {
        border-color: #8be7ae; /* Change the border color on hover */
        transform: scale(1.06);
        transition: .6s;
    }

    .details {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        background-color: #8be7aef3;
        width: 50%;
        max-width: 450px; /* Adjust the width as needed */
        padding: 15px;
        max-height: auto;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    }
    
    .details-content {
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
    }
    
    .details-container {
        display: flex;
        align-items: center; /* Align items vertically */
    }
    
    .details-image {
        width: 100px;
        height: 140px;
        margin-right: 10px; /* Add some space between the image and the text */
        border-radius: 5px;
    }
    
    .details-info {
        flex-grow: 1; /* Allow this container to grow to fill the remaining space */
    }
    
        .details-title {
            color: #dc5318;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;

        }
        
        .details-rating {
            font-size: 14px;
        }

        .details-genres {
            font-size: 14px;
        }
        
    .synopsis {
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;
    }


    
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: #000000;
        font-size: 20px;

    }
